#editorjs {
    width: 100%;
    height: auto;
}

/* Style paragraph blocks */
.ce-block__content {
    width: 100%;
    text-align: justify;
}

.ce-paragraph {
    width: 100%;
    margin: 0;
    padding: 0;
}

.ce-block__content,
.ce-toolbar__content {
    max-width: unset;
}